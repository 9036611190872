import { ref } from "vue";
import { useRouter } from "vue-router";
import { pageappnotice, userReadNotice } from "@/api/appNotice"; // 导入接口

import { showLoading, hideLoading, showToast } from "@/utils/utils"; // 导入封装的加载和提示方法

export default {
  name: "newTutoria",

  setup() {
    const router = useRouter();

    const onClickLeft = () => history.back();

    const loading = ref(false); // 控制加载状态

    const finished = ref(false); // 控制是否已加载完所有数据

    const items = ref([]); // 存储新闻数据

    const currentPage = ref(1); // 当前加载的页码

    const pageSize = 10; // 每次加载的条目数

    const total = ref(0); // 总数据条目数
    // 从本地存储中获取并解析 userInfo

    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    const toDetail = async item => {
      if (!userInfo || !userInfo.loginName) {
        console.error("LoginName is missing from localStorage.");
        return;
      }

      try {
        showLoading("Marking as read..."); // 显示加载状态
        // 调用用户已读消息接口

        const res = await userReadNotice({
          loginName: userInfo.loginName,
          noticeId: item.id // 新闻的 ID

        });
        hideLoading(); // 请求完成后隐藏加载状态

        if (res.code === 1) {
          // 接口调用成功，跳转到详情页面
          router.push({
            path: "/home/newTutoria/details",
            query: {
              data: JSON.stringify(item)
            }
          });
        } else {
          showToast(res.msg || "Failed to mark notice as read");
          console.error("Failed to mark notice as read", res.msg);
        }
      } catch (error) {
        hideLoading(); // 请求失败时隐藏加载状态

        showToast("Error marking notice as read");
        console.error("Error marking notice as read", error);
      }
    }; // 加载更多数据


    const onLoad = async () => {
      loading.value = true;
      showLoading("Loading news..."); // 显示加载状态

      try {
        // 调用接口获取数据
        const response = await pageappnotice({
          type: "0",
          current: currentPage.value,
          size: pageSize
        });
        hideLoading(); // 请求完成后隐藏加载状态

        if (response.code === 1) {
          const newItems = response.data.records;
          total.value = response.data.total; // 获取总数据条目数
          // 将新获取的数据添加到列表中

          items.value.push(...newItems); // 如果加载的数据已经达到或超过总条目数，标记为已加载完成

          if (items.value.length >= total.value) {
            finished.value = true;
          } else {
            // 增加页码，准备下次加载
            currentPage.value++;
          }
        } else {
          showToast(response.msg || "Failed to load news");
          console.error("获取新闻数据失败", response.msg);
        }
      } catch (error) {
        hideLoading(); // 请求失败时隐藏加载状态

        showToast("Error loading news");
        console.error("请求失败", error);
      }

      loading.value = false; // 停止加载状态
    };

    return {
      onClickLeft,
      loading,
      finished,
      items,
      onLoad,
      toDetail
    };
  }

};